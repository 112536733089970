import Vue from 'vue'
import { Fragment } from 'vue-frag'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueYoutube from 'vue-youtube'

Vue.use(VueYoutube)
Vue.config.productionTip = false
Vue.prototype.$modernizr = window.Modernizr;

let vueApp = new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
