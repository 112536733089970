import Vue from 'vue'
import VueRouter from 'vue-router'
import http from 'vue-resource'

Vue.use(VueRouter);
Vue.use(http);

const routes = [
  {
    path: '/',
    name: 'inicio',
    component: () => import(/* webpackChunkName: "principal" */ '../views/Inicio.vue')
  },
  {
    path: '/inicio/:pagina',
    name: 'inicio',
    component: () => import(/* webpackChunkName: "principal" */ '../views/Inicio.vue')
  },
  {
    path: '/capitulo/:id',
    name: 'capitulo',
    component: () => import(/* webpackChunkName: "principal" */ '../views/Capitulo.vue')
  },
  {
    path: '/temporadas',
    name: 'temporadas',
    component: () => import(/* webpackChunkName: "principal" */ '../views/Temporadas.vue')
  },
  {
    path: '/bso-escribe/:pagina',
    name: 'bso-escribe',
    component: () => import(/* webpackChunkName: "secundario" */ '../views/BSO-Escribe.vue')
  },
  {
    path: '/bso-escucha/:pagina',
    name: 'bso-escucha',
    component: () => import(/* webpackChunkName: "secundario" */ '../views/BSO-Escucha.vue')
  },
  {
    path: '/ilustres/:pagina',
    name: 'ilustres',
    component: () => import(/* webpackChunkName: "secundario" */ '../views/Ilustres.vue')
  },
  {
    path: '/ilustre/:id',
    name: 'ilustre',
    component: () => import(/* webpackChunkName: "secundario" */ '../views/Ilustre.vue')
  },
  {
    path: '/quienes-somos',
    name: 'quienes-somos',
    component: () => import(/* webpackChunkName: "secundario" */ '../views/Quienes-Somos.vue')
  },
  {
    path: '/texto/:id',
    name: 'texto',
    component: () => import(/* webpackChunkName: "secundario" */ '../views/Texto.vue')
  },
  {
    path: '/cuadernos',
    name: 'cuadernos',
    component: () => import(/* webpackChunkName: "secundario" */ '../views/Cuadernos.vue')
  },
  {
    path: '/texto-editor/:id',
    name: 'texto-editor',
    component: () => import(/* webpackChunkName: "editores" */ '../views/TextoEditor.vue')
  },
  {
    path: '/capitulo-editor/:id',
    name: 'capitulo-editor',
    component: () => import(/* webpackChunkName: "editores" */ '../views/CapituloEditor.vue')
  },
  {
    path: '/entrevista-editor/:id',
    name: 'entrevista-editor',
    component: () => import(/* webpackChunkName: "editores" */ '../views/EntrevistaEditor.vue')
  },
  {
    path: '/ilustre-editor/:id',
    name: 'ilustre-editor',
    component: () => import(/* webpackChunkName: "editores" */ '../views/IlustreEditor.vue')
  },
  {
    path: '/busqueda/:busqueda/:pagina',
    name: 'busqueda',
    component: () => import(/* webpackChunkName: "principal" */ '../views/Busqueda.vue')
  },
  {
    path: '/especiales/:especial/:pagina',
    name: 'especiales',
    component: () => import(/* webpackChunkName: "principal" */ '../views/Especiales.vue')
  }
]

const router = new VueRouter({
  mode:'hash',
  routes,
})

export default router
