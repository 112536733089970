<template>
	<div class="main-style ml-0 mr-0" style="width:50px;">
		<v-btn :class="getClass()" fab dark small :style="getStyle()" :href=link target="_blank">
		</v-btn>
		<div class="text-style">{{nombre}}</div>
	</div>
</template>

<script>
export default {
	data () {
		return {}
	},
	props: ['color','imagen','link','nombre','size'],
	methods:{
		getStyle: function(){
			let estilo = 'background-color:' + this.color + '!important;';
			estilo += 'background-image: url(' + require('../assets/' + this.imagen) + ");";
			estilo += 'background-size:contain;'
			return estilo;
		},
		getClass: function(){
			if (this.size=='small'){
				return 'button-style-' + this.size;
			}
			else{
				return 'button-style';
			}
		}
	}
};
</script>
<style>
.main-style{
	display: flex;
	flex-direction: column;
	align-items: center;
}
.button-style{
	width:32px!important;
	height:32px!important;
}
.button-style-small{
	width:26px!important;
	height:26px!important;
}
.text-style{
	font-family:Roboto, sans-serif;
	font-size:10px;
	font-weight:500;
	color:white;
}
</style>