<template>
  <v-app>
    <v-main style="background-color:black;">
      <v-flex xs12 lg10 xl8 offset-xl2 offset-lg1>
        <v-app-bar fixed color="red" dark elevate-on-scroll :style="getBarStyle()">
          <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click="menu=true;" ></v-app-bar-nav-icon>
          <v-toolbar-title class="headline text-uppercase" :style="getBarHeaderStyle()">
            <div class="top-bar fondo-1"></div>
            <a href="/" class="top-bar fondo-2a" v-if="$vuetify.breakpoint.mdAndUp"></a>
            <a href="/" class="top-bar fondo-2b" v-if="$vuetify.breakpoint.smAndDown"></a>
            <div class="fondo-3" v-if="$vuetify.breakpoint.mdAndUp">(BANDA SONORA ORIGINAL)</div>-->
            <div class="fondo-4a" v-if="$vuetify.breakpoint.mdAndUp"></div>
            <div class="fondo-4b" v-if="$vuetify.breakpoint.mdAndUp"></div>
            <div class="fondo-5a" v-if="$vuetify.breakpoint.mdAndUp"></div>
            <div class="fondo-5b" v-if="$vuetify.breakpoint.mdAndUp"></div>
            <!--<div class="top-bar fondo-3"></div>-->
           
           
            <!-- <v-img src="./assets/logo-bso_sml.webp"></v-img> -->
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="isAdmin && $vuetify.breakpoint.mdAndUp" style="position:absolute;right:50px;top:20px;z-index:50" @click="salirAdmin()">Salir Admin</v-btn>

          <template v-slot:extension>
            <v-layout>
              <v-tabs  v-if="$vuetify.breakpoint.mdAndUp" id="navBar" fixed-tabs router show-arrows dark background-color="red darken-2" slider-size="3" slider-color="orange lighten-2">
                <v-tab to="/" class="bar-button" style="width:50px"><v-icon>mdi-home</v-icon></v-tab>
                <v-tab to="/temporadas" class="bar-button">Temporadas</v-tab>
                <v-tab to="/bso-escribe/1" class="bar-button">¡BSO escribe!</v-tab>
                <v-tab to="/bso-escucha/1" class="bar-button">¡BSO escucha!</v-tab>
                <v-tab to="/ilustres/1" class="bar-button">Ilustres</v-tab>
                <v-tab to="/quienes-somos" class="bar-button">Quienes Somos</v-tab>
                <v-tab to="/cuadernos">¡Cuadernos!</v-tab>
              </v-tabs> 
              <v-layout>
                <v-expand-x-transition>
                  <v-text-field v-model="textoBusqueda" :class="'search-textbox ' + searchClass" dark background-color="primary" :style="'width:' + searchWidth + 'px'" 
                    prepend-inner-icon="mdi-magnify" 
                    v-on:focus="searchWidth=180;searchClass='search-textbox-focus'"
                    v-on:blur="searchWidth=40;searchClass='search-textbox-blur'"
                    v-on:keyup.enter="realizarBusqueda()" ></v-text-field>
                </v-expand-x-transition>
              </v-layout>
            </v-layout>
            
          </template>

        </v-app-bar>
        <div :style="getMainStyle()">
          <v-navigation-drawer dark style="background-color:#DD3333;border-color:#DD3333;" fixed temporary v-model="menu"> 
              <v-list class="pa-1" >
                <v-list-item>
                  <v-list-item-content class="ma-0 pa-0" style="height:32px;">
                    <v-list-item-title style="height:32px;"><img height="32px" :src="require('./assets/fondo-2-new.png')" /></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-list class="pt-0" >
                <v-divider></v-divider>
                <v-list-item v-for="pag in paginas" :key="pag.titulo" :href="'#' + pag.ruta">
                  <v-list-item-action>
                    <v-icon>{{pag.icono}}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{pag.titulo}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-group class="especiales-bar">
                  <template v-slot:activator>
                    <v-list-item-action class="especiales-bar">
                      <v-icon>mdi-movie-open</v-icon>
                    </v-list-item-action>
                    <v-list-item-title class="especiales-bar">ESPECIALES</v-list-item-title>
                  </template>
                  <v-list-item style="padding-left:50px" v-for="esp in especiales" :key="esp.titulo" :href="'#' + esp.link">
                    <v-list-item-action class="especiales-bar">
                      <v-icon>{{esp.icono}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{esp.titulo}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
              <v-layout class="pb-2 pt-2">
                <bar-icon-link size="small" color='#00bd40' imagen="spotify_link_bw.png" nombre="Spotify" link="https://open.spotify.com/show/14pzdz8zdRHMeinocZwbIq?si=ou4qkxTPQP-wFMbRhQvx4A" />
                <bar-icon-link size="small" color='white' imagen="itunes_link_bw.png" nombre="Itunes" link="https://podcasts.apple.com/ar/podcast/bso-banda-sonora-original/id1397108131" />
                <bar-icon-link size="small" color='white' imagen="ivoox_link_bw.png" nombre="Ivoox" link="http://www.ivoox.com/escuchar-bso-banda-sonora-original_nq_91917_1.html" />
                <bar-icon-link size="small" color='white' imagen="mixcloud_link.png" nombre="Mixcloud" link="https://www.mixcloud.com/bsoradio/" />
                <bar-icon-link size="small" color='white' imagen="youtube_link_bw.png" nombre="YouTube" link="https://www.youtube.com/channel/UCAwA_hNlgUomg0wAZa18DFA" />
              </v-layout>
              <v-layout>
                <bar-icon-link size="small" color='white' imagen="facebook_link_bw.png" nombre="Facebook" link="https://www.facebook.com/bsoradio/" />
                <bar-icon-link size="small" color='white' imagen="instagram_link_bw.png" nombre="Instagram" link="https://www.instagram.com/bsoradio/" />
                <bar-icon-link size="small" color='white' imagen="twitter_link_bw.png" nombre="Twitter" link="https://twitter.com/BSOradio" />
              </v-layout>
            </v-navigation-drawer>

          <v-main style="background-color:#E0E0E0;">
            <transition name="fade">
              <router-view></router-view>
            </transition>
            <v-btn class="v-btn-grad" color="primary" dark bottom right fab fixed @click="scrollToTop()">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </v-main>
        </div>
      </v-flex>
      <input v-if="$root.$isAdmin" id="clip" style="display:none" type="text" />
    </v-main>
  </v-app>
</template>

<script>
import BarIconLink from './components/BarIconLink'

export default {
  name: "App",
  components: {
    BarIconLink
  },
  data: () => ({
    paginas: [
      { titulo:'Inicio',ruta:'/inicio/1',icono:'mdi-home'},
      { titulo:'Temporadas',ruta:'/temporadas',icono:'mdi-clipboard-text'},
      { titulo:'¡BSO Escribe!',ruta:'/bso-escribe/1',icono:'mdi-lead-pencil'},
      { titulo:'¡BSO Escucha!',ruta:'/bso-escucha/1',icono:'mdi-microphone'},
      { titulo:'Ilustres',ruta:'/ilustres/1',icono:'mdi-brush'},
      { titulo:'Quienes Somos',ruta:'/quienes-somos',icono:'mdi-face'},
      { titulo:'¡Cuadernos!',ruta:'/cuadernos',icono:'mdi-book-open-variant'},
    ],
    especiales: [
        {titulo:"AUTORES", seccion:"AUTORES", link:"/especiales/AUTORES/1",icono:"mdi-fountain-pen-tip"},
        {titulo:"AL OESTE", seccion:"AL_OESTE", link:"/especiales/AL_OESTE/1",icono:"mdi-account-cowboy-hat"},
        {titulo:"NOIR", seccion:"NOIR", link:"/especiales/NOIR/1",icono:"mdi-incognito"},
        {titulo:"SCI-FI", seccion:"SCI-FI", link:"/especiales/SCI-FI/1",icono:"mdi-alien"},
    ],
    // basepath: "http://localhost/bso-radio/",
    menu:false,
    isAdmin:false,
    searchWidth:40,
    searchClass:'search-textbox-blur',
    textoBusqueda:null
  }),
  created: function(){
    let _this = this;
    //this.$root.$basepath = 'http://localhost/bso-api/';
    this.$root.$basepath = 'http://192.168.1.101/bso-api/';
    this.$root.$webp = (this.$modernizr.webp?"&webp=1":"");
    this.$root.$isAdmin = false;
    this.$root.$reloadScrollBars = function () {
      document.documentElement.style.overflow = 'auto';  // firefox, chrome
      document.body.scroll = "yes"; // ie only
    }
    this.$root.$unloadScrollBars = function () {
      document.documentElement.style.overflow = 'hidden';  // firefox, chrome
      document.body.scroll = "no"; // ie only
    }
    this.$http.get(this.$root.$basepath + "api/init.php").then(result => {
        result.json().then(res=>{
            _this.isAdmin=res.isAdmin;
            _this.$root.$isAdmin = _this.isAdmin;
          });
        }, error => {
            //this.mostrarError(error);
      });
  },
  methods: {
    getMainStyle: function(){
      if (this.$vuetify.breakpoint.mdAndUp){
        return "margin-top:138px";
      }
      else{
        return "margin-top:120px";
      }
    },
    getBarStyle: function(){
      let style = "";
      if (this.$vuetify.breakpoint.mdAndUp){
        style="height:90px;";
      }
      else{
        style="height:72px;";
      }
      if (this.$vuetify.breakpoint.xl){
        style+="width:66.66%;left:16.66%";
      }
      else if (this.$vuetify.breakpoint.lg){
        style+="width:83.33%;left:8.33%";
      }
      else {
        style+="width:100%;left:0%";
      }
      return style;
    },
    getBarHeaderStyle: function(){
      if (this.$vuetify.breakpoint.mdAndUp){
        return "height:90px";
      }
      else{
        return "height:72px";
      }
    },
    salirAdmin: function(){
      let _this = this;
      this.$http.post(this.$root.$basepath + "api/logout.php").then(result => {
        result.json().then(res=>{
            _this.isAdmin=false;
            _this.$root.$isAdmin=false;
            _this.$router.go('inicio');
          });
        }, error => {
            //this.mostrarError(error);
      });
    },
    scrollToTop: function(){
      this.$vuetify.goTo(0, {duration: 500,easing: 'linear'});
    },
    realizarBusqueda: function(){
      if (this.textoBusqueda!=null && this.textoBusqueda.length>=3){
        let busq=encodeURI(this.textoBusqueda);
        if (this.$route.name=='busqueda'){
          this.$root.$emit('busquedaEvent',busq,1);
        }
        else{
          this.$router.push({name:'busqueda',params:{busqueda:busq,pagina:1}});
        }
      }
    }
  }
};
</script>
<style >
@font-face {
  font-family: SoraSemiBold;
  src:url(/fonts/Sora-SemiBold.ttf);
  font-weight: bold;
}
.v-app-bar__nav-icon{
  position: absolute;
  z-index: 6;
}
/* .v-app-bar__nav-icon i{
  color:red!important;
} */
.v-tab--active{
  background-color:rgba(255,255,255,0.2);
}
.v-toolbar__content{
  height: 100%!important;
}
.top-bar{
  position:absolute;
  height:100%;
  width:100%;
  top: 0px;
  left: 0px;
}
.fondo-1{
  background-image: url(./assets/fondo-1-new.png);
  background-position: 0px 0px;
  background-repeat: repeat-y;
  background-size: 100%;
  z-index: 1;
}
.fondo-2a{
  background-image: url(./assets/fondo-2-new.png);
  background-position: center 35%;
  background-repeat: no-repeat;
  z-index: 2;
}
.fondo-2b{
  background-image: url(./assets/fondo-2-new.png);
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 2;
}
.fondo-3{
  font-family: SoraSemiBold;
  font-size: 13px;
  bottom: 0px;
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0px;
  letter-spacing: 1px;
  z-index: 3;
}
.fondo-4a{
  height:25px;
  width:25px;
  z-index: 4;
  border: 3px #e5361f solid;
  position: absolute;
  top:5px;
  left:10%;
}
.fondo-4b{
  height:25px;
  width:25px;
  z-index: 5;
  border: 3px #e5361f solid;
  position: absolute;
  top:17px;
  left:calc(10% + 12px);
}
.fondo-5a{
  height:2px;
  width:100px;
  z-index: 4;
  border: 2px #e5361f solid;
  position: absolute;
  bottom:15px;
  right:10%;
  top: unset;
  left: unset;
}
.fondo-5b{
  height:2px;
  width:100px;
  z-index: 5;
  border: 2px #e5361f solid;
  position: absolute;
  bottom:5px;
  right:calc(10% + 50px);
  top: unset;
  left: unset;
}

.fade-enter {
	opacity: 0;
}

.fade-enter-active {
	transition: opacity 500ms ease;
}

.fade-leave {
	
}

.fade-leave-active {
	transition: opacity 500ms ease;
	opacity: 0;
}

@keyframes fade-in {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes slide-in-left {
  from {background-position: -200px 0px;}
  to {background-position: 0px 0px}
}
@keyframes slide-in-right {
  from {background-position: 200% 0%;}
  to {background-position: 100% 0%;}
}

.especiales-bar, .especiales-bar i{
  color:white!important;
}
.search-textbox,.search-textbox div,.search-textbox input{
  height: 48px!important;
  line-height: 48px!important;
  margin-top:0px!important;
  margin-bottom:0px!important;
  padding-top:0px!important;
  padding-bottom:0px!important;
}

.search-textbox i{
  padding-left:15px;
}
.search-textbox .v-text-field__slot{
  align-items: center;
}
.search-textbox-focus {
  width:180px;
  animation-name: example-focus;
  animation-duration: 300ms;
}

@keyframes example-focus {
  from {width: 40px;}
  to {width: 180px;}
}

.search-textbox-blur {
  width:40px;
  animation-name: example-blur;
  animation-duration: 300ms;
}

@keyframes example-blur {
  from {width: 180px;}
  to {width: 40px;}
}

.v-toolbar__extension{
  padding-left: 0px!important;
  padding-right: 0px!important;
}

.bottom-sheet{
  background: linear-gradient(157deg, rgba(255,63,51,1) 0%, rgba(255,103,38,1) 65%, rgba(255,210,0,1) 100%) !important;
}
.v-btn-grad{
  background: linear-gradient(157deg, rgba(255,63,51,1) 0%, rgba(255,103,38,1) 65%, rgba(255,210,0,1) 100%) !important;
}
</style>